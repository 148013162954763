import HomeIcon from '@material-ui/icons/HomeOutlined';
import ArticleBody from 'components/ArticleBody';
import CardSection from 'components/CardSection'
import Header from 'components/Header';
import Seo from "components/Seo";
import { graphql } from "gatsby";
import * as React from 'react'
import Layout from "components/Layout";

const buttons = [
  {
    text: 'Return to home',
    to: '/',
    icon: <HomeIcon />
  },
]

export default ({ data }) => (
  <Layout>
    <Seo
      title={data.markdownRemark.frontmatter.name}
      description={data.markdownRemark.frontmatter.title}
    />
    <Header
      title={data.markdownRemark.frontmatter.name}
      subtitle={data.markdownRemark.frontmatter.title}
    />
    <CardSection scheme2 buttons={buttons}>
      <ArticleBody key={data.markdownRemark.frontmatter.name} content={data.markdownRemark.html} />
    </CardSection>
  </Layout>

);

export const dataQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { name: { eq: "Terms & Conditions" } }) {
      html
      frontmatter {
        title
        name
      }
    }
  }
`;
