import * as React from 'react'
import {
  Typography
} from '@material-ui/core';

function ArticleBody(props) {
  return (
    <Typography
      // key={props.key}
      style={{lineHeight: 1.75}}
      color={props.color ? props.color : "textPrimary"}
      align={props.align ? props.align : 'justify'}
      variant={props.variant ? props.variant : 'body1'}
      component="div"
      dangerouslySetInnerHTML={{ __html: props.content }}
    />
  )
}

export default ArticleBody